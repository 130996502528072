<wb-grid [fullWidth]="true">
  <wb-grid-row>
    <wb-grid-col mq6="12">
      <wb-heading size="xs" class="spacing-xs spacing-top-xs"
        >Application data
      </wb-heading>
    </wb-grid-col>
  </wb-grid-row>
  <wb-grid-row class="gutter">
    <wb-grid-col mq6="6">
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="
            this.vPackageService.packageDataPost!.application_instances!
              .diagnostic_name
          "
          ngDefaultControl
          [name]="this.formGroup.diagnosticName"
          required
          #diagnosticName="ngModel"
          [error]="
            this.validationService.isComboBoxInValid(
              this.formGroup.diagnosticName,
              this.diagnosticName,
              this.vPackageService.packageDataPost!.application_instances!
                .diagnostic_name,
              getDiagnosticNameOptions()
            )
          "
          (wbcomboboxinput)="onDiagnosticNameInputChange($event)"
        >
          <label slot="label">Diagnostic name*</label>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-found-text="No diagnostic names found"
          (wbcomboboxlistselectionchange)="onDiagnosticNameSelectChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let dname of diagnosticNames"
            value="{{ dname.diagnostic_name }}"
            >{{ dname.diagnostic_name }}
          </wb-combobox-list-item>
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="diagnostic name"
            [type]="format.Pick"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.diagnosticName,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.diagnosticName,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
      <wb-input
        [disabled]="true"
        as="input"
        [(ngModel)]="
          ncdNames[
            this.vPackageService.packageDataPost!.application_instances!
              .diagnostic_name
          ]
        "
        name="ncdName"
        ngDefaultControl
        inputmode="text"
        class="spacing-xs"
        (wbinput)="onNcdNameInputChange($event)"
      >
        <span slot="label"> NCD Name* </span>
      </wb-input>
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="this.vPackageService.packageDataPost!.ecu_package_link"
          name="hardwarelink"
          ngDefaultControl
          #hardwarelink="ngModel"
          (wbcomboboxinput)="onEcuLinkInputChange($event)"
          [disabled]="packageLinkSpinner"
        >
          <label slot="label">ECU package link (optional) </label>
          <wb-input-action slot="actions" *ngIf="packageLinkSpinner">
            <wb-spinner id="loading-spinner"></wb-spinner>
          </wb-input-action>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No hardware link selected"
          no-items-found-text="No hardware link found"
          (wbcomboboxlistselectionchange)="onEcuLinkSelectChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let ecuPackage of vPackageService.filteredArray"
            value="{{ ecuPackage.id }}"
            >{{ ecuPackage.name }}
          </wb-combobox-list-item>
        </wb-combobox-list>
      </wb-combobox>
    </wb-grid-col>
    <wb-grid-col mq6="6">
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="vPackageService.level"
          ngDefaultControl
          [name]="this.formGroup.vecuLevel"
          required
          #vecuLevel="ngModel"
          [error]="
            this.validationService.isComboBoxInValid(
              this.formGroup.vecuLevel,
              this.vecuLevel,
              vPackageService.level,
              levelOptions
            )
          "
          (wbcomboboxinput)="onLevelInputChange($event)"
        >
          <label slot="label">vECU level*</label>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No model series selected"
          no-items-found-text="No model series found"
          (wbcomboboxlistselectionchange)="onLevelSelectChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let level of levelOptions"
            value="{{ level }}"
          >
            {{ level }}
          </wb-combobox-list-item>
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="vECU level"
            [type]="format.Pick"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.vecuLevel,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.vecuLevel,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
      <wb-input
        as="input"
        [(ngModel)]="vPackageService.packageDataPost!.vendor"
        name="vendor"
        ngDefaultControl
        inputmode="text"
        class="spacing-xs"
        #vendor="ngModel"
        [error]="
          validationService.isOptionalFieldInvalid(
            this.formGroup.vendor,
            vendor,
            vPackageService.packageDataPost!.vendor!,
            format.Name
          )
        "
      >
        <span slot="label"> Vendor (optional) </span>
        <div slot="message">
          <app-validation-notification
            name="Hardware security module"
            [type]="format.Name"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.vendor,
                validationStatus.Length
              ) &&
              validationService.getFormStatus(
                this.formGroup.vendor,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-input>
      <wb-radio-group>
        <wb-checkbox
          [(ngModel)]="
            (vPackageService.packageDataPost?.application_instances
              ?.hardware_security_module)!.name
          "
          (wbchange)="onHsmChange($event)"
          class="spacing-xs"
          [checked] = "hsmChecked"
          ngDefaultControl
          name="hsm"
          #hsm="ngModel"
          [error]="
            validationService.isOptionalFieldInvalid(
              this.formGroup.hsm,
              hsm,
              (vPackageService.packageDataPost?.application_instances
                ?.hardware_security_module)!.name!,
              format.Name
            )
          "
        >
          Hardware security module (optional)
          <div slot="message">
            <app-validation-notification
              name="Hardware security module"
              [type]="format.Name"
              *ngIf="
                validationService.getFormStatus(
                  this.formGroup.hsm,
                  validationStatus.Length
                ) &&
                validationService.getFormStatus(
                  this.formGroup.hsm,
                  validationStatus.Touched
                )
              "
            >
            </app-validation-notification>
          </div>
        </wb-checkbox>
      </wb-radio-group>
    </wb-grid-col>
  </wb-grid-row>
  <wb-grid-row class="gutter">
    <wb-grid-col mq6="6">
      <wb-heading size="xs" class="spacing-xs"
        >Add vECU Applications to configuration
      </wb-heading>
      <wb-grid-row class="spacing-xs">
        <wb-grid-col mq6="11">
          <wb-input
            as="input"
            [(ngModel)]="vecuApplication"
            [name]="this.formGroup.vecuApp"
            ngDefaultControl
            inputmode="text"
            class="spacing-xs"
            required
            #vecuApp="ngModel"
            [error]="
              validationService.isRequiredMultiFieldInvalid(
                this.formGroup.vecuApp,
                vecuApp,
                vecuApplication,
                format.Name,
                this.vPackageService.packageDataPost?.application_instances
                  ?.vecu_applications
              )
            "
          >
            <span slot="label">vECU Application name*</span>
            <div slot="message">
              <app-validation-notification
                name="vECU Application"
                [type]="format.Name"
                *ngIf="
                  validationService.getFormStatus(
                    this.formGroup.vecuApp,
                    validationStatus.Length
                  ) &&
                  validationService.getFormStatus(
                    this.formGroup.vecuApp,
                    validationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
              <app-validation-notification
                name="Please add at least one vECU Application"
                [type]="format.Info"
                *ngIf="
                  !validationService.getFormStatus(
                    this.formGroup.vecuApp,
                    validationStatus.Length
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-input>
        </wb-grid-col>
        <wb-grid-col mq6="1">
          <wb-button
            class="spacing-xs"
            (click)="addVecuApplication(vecuApp)"
            variant="secondary"
            >Add
          </wb-button>
        </wb-grid-col>
      </wb-grid-row>
    </wb-grid-col>
    <wb-grid-col mq6="6">
      <wb-heading size="xs" class="spacing-xs"
        >Add Sensors / Actors to configuration (optional)
      </wb-heading>
      <wb-grid-row class="spacing-xs">
        <wb-grid-col mq6="11">
          <wb-input
            as="input"
            [(ngModel)]="sensoractor"
            [name]="formGroup.sensor"
            ngDefaultControl
            inputmode="text"
            class="spacing-xs"
            #sensor="ngModel"
            [error]="
              validationService.isOptionalFieldInvalid(
                this.formGroup.sensor,
                sensor,
                sensoractor,
                format.Name
              )
            "
          >
            <span slot="label">Sensor/Actor name (optional)</span>
            <div slot="message">
              <app-validation-notification
                name="Sensor"
                [type]="format.Name"
                *ngIf="
                  validationService.getFormStatus(
                    this.formGroup.sensor,
                    validationStatus.Length
                  ) &&
                  validationService.getFormStatus(
                    this.formGroup.sensor,
                    validationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-input>
        </wb-grid-col>
        <wb-grid-col mq6="1">
          <wb-button
            class="spacing-xs"
            (click)="addSensorActor(sensor)"
            variant="secondary"
            >Add
          </wb-button>
        </wb-grid-col>
      </wb-grid-row>
    </wb-grid-col>
  </wb-grid-row>
  <wb-grid-row>
    <wb-grid-col mq6="6">
      <wb-grid-row
        *ngFor="
          let vecu of vPackageService.packageDataPost?.application_instances
            ?.vecu_applications;
          let i = index
        "
      >
        <wb-grid-col start-col-mq6="1" mq6="6">
          <wb-text
            class="spacing-bot-16 spacing-left-8 spacing-top-12"
            size="m"
            tag="div"
          >
            {{ vecu.name }}
          </wb-text>
        </wb-grid-col>
        <wb-grid-col start-col-mq6="12" mq6="1">
          <wb-round-button size="m" (click)="deleteVecuApplication(i)">
            <wb-icon name="bds/trash/16" class="icon-color"></wb-icon>
            Delete
          </wb-round-button>
        </wb-grid-col>
      </wb-grid-row>
    </wb-grid-col>
    <wb-grid-col mq6="6">
      <wb-grid-row
        *ngFor="
          let sa of vPackageService.packageDataPost?.application_instances
            ?.sensor_actors;
          let i = index
        "
      >
        <wb-grid-col mq6="6">
          <wb-text
            size="m"
            tag="div"
            class="spacing-bot-16 spacing-left-8 spacing-top-12"
          >
            {{ sa.name }}
          </wb-text>
        </wb-grid-col>
        <wb-grid-col start-col-mq6="12" mq6="1">
          <wb-round-button size="m" (click)="deleteSensoractor(i)">
            <wb-icon name="bds/trash/16" class="icon-color"></wb-icon>
            Delete
          </wb-round-button>
        </wb-grid-col>
      </wb-grid-row>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
